import { UploadedImage } from '../../../../../../types';
import ImageTag from '../../../../../shared/ImageTag/ImageTag';
import styles from './BrandLogo.module.scss';
import { Anchor } from '../../../../../shared/Anchor';

export interface BrandLogoProps {
  logo?: UploadedImage;
  heading?: string;
  urlEmailAddress?: string;
  alt?: string;
}

export default function BrandLogo(props: BrandLogoProps) {
  const { logo, alt, urlEmailAddress, heading } = props;

  if (!logo?.path) {
    return null;
  }

  const image = (
    <ImageTag src={logo?.path} alt={alt} testId="brand-logo-image" />
  );

  return (
    <div className={styles.wrapper}>
      {heading && <div className={styles.heading}>{heading}</div>}
      <div className={styles.logoWrapper}>
        {urlEmailAddress ? (
          <Anchor to={urlEmailAddress}>{image}</Anchor>
        ) : (
          image
        )}
      </div>
    </div>
  );
}
