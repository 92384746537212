import React, { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import {
  FacetType,
  HubModuleWithDiscovery,
  PropsWithTestId,
} from '../../../../../types';
import {
  HubModuleTypes,
  HubModuleThemes,
  HubVisualStyles,
  HubProductFilterPosition,
} from '../../../../../constants';
import { useProductModuleFilter } from '../../productFilter/hooks';
import {
  FetchHubModuleDiscoveryParams,
  HubDiscoveryValues,
} from '../../../../../store/features/discovery';
import { HubModuleWrapper } from '../HubModuleWrapper';
import {
  ProductModule,
  ProductFilterProvider,
  ProductFilter,
  ProductModuleHeading,
} from '../../productFilter/products';
import { FilterFacets } from '../../productFilter/filters';
import { checkDefaultFiltersApplied } from '../../helpers';

import { ProductTopFilter } from '../../productFilter/products/ProductTopFilter';
import BrandLogo, {
  BrandLogoProps,
} from '../../productFilter/filters/BrandLogo';
import ShowMoreButton from '../components/ShowMoreButton';

export enum RowsOptions {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
}

export type ProductModuleType = HubModuleWithDiscovery & {
  '@type': HubModuleTypes;
  title: string;
  introText?: string;
  filterTitle: string;
  elementId: string;
  filterPosition: HubProductFilterPosition;
  brandLogoDisplayed: boolean;
  brandLogo: BrandLogoProps;
  searchQueryData: Pick<
    HubDiscoveryValues,
    | 'largeFeaturedContentIds'
    | 'featuredContentIds'
    | 'facetsConfig'
    | 'fetchIds'
    | 'deliveryType'
    | 'subBrands'
    | 'sectors'
    | 'topics'
    | 'location'
    | 'language'
    | 'awardGroup'
    | 'informationType'
    | 'partners'
    | 'creditPartners'
    | 'type'
  >;
};

export interface AggregatedProductModuleProps {
  module: ProductModuleType;
  moduleName: FetchHubModuleDiscoveryParams['moduleName'];
  brandName: string;
  defaultValues: HubDiscoveryValues;
  theme: keyof typeof HubModuleThemes;
  resultsCount: number;
  showLeftFilter: boolean;
  showTopFilter: boolean;
  showMoreButtonURL?: string;
  bottomShowMoreButtonLabel?: string;
  topShowMoreButtonLabel?: string;
}

export function AggregatedProductModule(
  props: PropsWithTestId<PropsWithChildren<AggregatedProductModuleProps>>,
) {
  const {
    module: {
      '@type': type,
      title,
      introText,
      filterTitle,
      discovery: { facets } = {},
      elementId,
      filterPosition,
      brandLogoDisplayed,
      brandLogo,
    },
    moduleName,
    brandName,
    defaultValues,
    theme,
    resultsCount,
    showMoreButtonURL,
    bottomShowMoreButtonLabel,
    topShowMoreButtonLabel,
    showLeftFilter,
    showTopFilter,
    children,
    testId,
  } = props;

  const form = useForm<HubDiscoveryValues>({
    defaultValues,
  });
  const selectedFilters = form.watch();

  const defaultFiltersApplied = checkDefaultFiltersApplied(
    defaultValues,
    form.getValues(),
  );

  const { handleFilterChange } = useProductModuleFilter({
    module: props.module,
    moduleName,
    brandName,
  });

  return (
    <HubModuleWrapper
      type={type}
      theme={theme}
      elementId={elementId}
      testId={testId}
    >
      <ProductModule
        heading={
          title && <ProductModuleHeading heading={title} text={introText} />
        }
        leftFilter={
          <ProductFilterProvider
            form={form}
            onChangeFilter={handleFilterChange}
          >
            <ProductFilter
              heading={filterTitle}
              onResetFilter={() => form.reset(defaultValues)}
              disableResetFilter={defaultFiltersApplied}
            >
              {facets && <FilterFacets facets={facets} />}
            </ProductFilter>
          </ProductFilterProvider>
        }
        showLeftFilter={showLeftFilter}
        topFilter={
          facets && (
            <ProductFilterProvider
              form={form}
              onChangeFilter={handleFilterChange}
            >
              <ProductTopFilter
                name="sectors"
                label="Choose an Interest"
                facets={facets}
                facetType={FacetType.SECTORS}
                onResetFilter={() => form.reset(defaultValues)}
              />
            </ProductFilterProvider>
          )
        }
        showTopFilter={showTopFilter}
        showMoreButton={
          <ShowMoreButton
            count={resultsCount}
            filterPosition={filterPosition}
            url={showMoreButtonURL}
            bottomButtonLabel={bottomShowMoreButtonLabel}
            topButtonLabel={topShowMoreButtonLabel}
            query={selectedFilters}
          />
        }
        brandLogo={
          brandLogoDisplayed && (
            <BrandLogo
              heading={brandLogo?.heading}
              logo={brandLogo?.logo}
              alt={brandLogo?.alt}
              urlEmailAddress={brandLogo?.urlEmailAddress}
            />
          )
        }
      >
        {children}
      </ProductModule>
    </HubModuleWrapper>
  );
}

AggregatedProductModule.defaultProps = {
  theme: HubVisualStyles.Light,
  showFilter: false,
};
