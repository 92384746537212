import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useDocumentClick } from '../../../../../../../hooks/useDocumentClick';
import s from './Dropdown.module.scss';
import { SelectOption } from '../../../../EmailOptInForm/components';

type Option = SelectOption & {
  selected: boolean;
};

export interface DropdownProps {
  name: string;
  options: Option[];
  onChange: (value: Option['value']) => void;
  onResetFilter: () => void;
  label: string;
  selectedOption?: Option;
}

export default function Dropdown(props: DropdownProps) {
  const { name, options, selectedOption, label, onChange, onResetFilter } =
    props;

  const [opened, setOpened] = useState(false);
  const ref = useDocumentClick(opened, () => {
    setOpened(false);
  });

  const { watch } = useFormContext();
  const filterValue = watch(name);

  const handleOpen = () => {
    setOpened(!opened);
  };

  const handleSelectValue = (value: Option['value']) => {
    onChange(value);
    setOpened(false);
  };

  const wrapperClassName = cn(s.wrapper, {
    [s.opened]: opened,
  });

  return (
    <div ref={ref} className={wrapperClassName}>
      <div onClick={handleOpen} className={s.select}>
        <span className={s.label}>
          {selectedOption ? selectedOption.label : label}
        </span>
        <span
          className={cn(s.arrowIcon, 'material-icons', {
            [s.expandMoreRoundedIcon]: !opened,
            [s.expandLessRoundedIcon]: opened,
          })}
        />
      </div>
      {opened && (
        <div className={s.dropdown}>
          <div className={s.scroller}>
            {filterValue.length > 0 && (
              <div
                onClick={onResetFilter}
                className={cn(s.option, s.resetButton)}
              >
                Reset filter
              </div>
            )}
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => {
                  handleSelectValue(option.value);
                }}
                className={cn(s.option, {
                  [s.selected]: option.selected,
                })}
              >
                {option.label}
              </div>
            ))}
          </div>
          <div className={s.bottomArrow}>
            <span
              className={cn(
                s.arrowIcon,
                s.expandMoreRoundedIcon,
                'material-icons',
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}
