import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Discovery } from '../../../../../../store/features/discovery';
import { FacetType } from '../../../../../../types';
import Dropdown from '../components/Dropdown';

export interface ProductTopFilterProps {
  name: string;
  label: string;
  facets: Discovery['facets'];
  facetType: FacetType;
  onResetFilter: () => void;
}

export function ProductTopFilter(props: ProductTopFilterProps) {
  const { facets, facetType, name, onResetFilter, label } = props;
  const { setValue } = useFormContext();
  const facet = facets[facetType];
  const options = useMemo(
    () =>
      facet?.map((option) => ({
        label: option.label,
        value: option.filterValue.values[0],
        selected: option.selected,
      })),
    [facet],
  );
  const selectedOption = options?.find((option) => option.selected);

  const handleChange = useCallback(
    (value: string | number) => {
      setValue(name, [value]);
    },
    [name, setValue],
  );

  if (!options) {
    return null;
  }

  return (
    <Dropdown
      name={name}
      options={options}
      selectedOption={selectedOption}
      onChange={handleChange}
      label={label}
      onResetFilter={onResetFilter}
    />
  );
}
