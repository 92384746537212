import React, { useMemo } from 'react';
import {
  AggregatedProductModule,
  ProductModuleType,
  RowsOptions,
} from '../../AggregatedProductModule';
import { ProductsGrid } from '../../../productFilter/products';
import { SiteCard } from '../../../productFilter/cards';
import { getDiscoveryContentByType, isFilterEnabled } from '../../../helpers';
import {
  DiscoveryFeaturedTypes,
  DiscoverySite,
} from '../../../../../../store/features/discovery';
import {
  DiscoveryContentTypes,
  HubProductFilterPosition,
} from '../../../../../../constants';
import { PropsWithTestId } from '../../../../../../types';
import { useHubContext } from '../../../context/hubContext';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectAudienceHubPrimaryTopic } from '../../../../audienceHub/store/features/options';

export interface CoursesModuleType extends ProductModuleType {
  featuredRowsCount: keyof typeof RowsOptions;
}

export interface CoursesModuleProps {
  module: CoursesModuleType;
}

export default function CoursesModule(
  props: PropsWithTestId<CoursesModuleProps>,
) {
  const { module, testId } = props;
  const { brandName } = useHubContext();

  const {
    featuredRowsCount,
    discovery: { records = [] } = {},
    searchQueryData,
    filterPosition,
  } = module;
  const FILTER_LEFT = filterPosition === HubProductFilterPosition.LEFT;
  const FILTER_TOP = filterPosition === HubProductFilterPosition.TOP;
  const filterEnabled = isFilterEnabled(searchQueryData.facetsConfig);
  const showLeftFilter = filterEnabled && FILTER_LEFT;
  const showTopFilter = filterEnabled && FILTER_TOP;
  const subBrands = useMemo(() => {
    const brands = searchQueryData.subBrands || [];
    return brands.length > 0 ? brands : [brandName];
  }, [brandName, searchQueryData.subBrands]);
  const mainFilterTopic = useRootSelector(selectAudienceHubPrimaryTopic);

  const defaultValues = useMemo(
    () => ({
      deliveryType: [],
      subBrands,
      sectors: [],
      topics: [],
      month: [],
      location: [],
      language: [],
      awardGroup: [],
      informationType: [],
      partners: [],
      creditPartners: [],
      mainFilterTopic,
    }),
    [mainFilterTopic, subBrands],
  );

  const { results = [], count: resultsCount = 0 } =
    getDiscoveryContentByType<DiscoverySite>(
      DiscoveryContentTypes.COURSE,
      records,
    );

  const featuredProducts = useMemo(() => {
    return results.filter(
      (item) => item.featuredType === DiscoveryFeaturedTypes.LARGE,
    );
  }, [results]);

  const products = useMemo(() => {
    return results
      .filter((item) => item.featuredType !== DiscoveryFeaturedTypes.LARGE)
      .slice(0, RowsOptions[featuredRowsCount] * (showLeftFilter ? 3 : 4));
  }, [results, featuredRowsCount, showLeftFilter]);

  return (
    <AggregatedProductModule
      module={module}
      moduleName="courses"
      brandName={brandName}
      defaultValues={defaultValues}
      resultsCount={resultsCount}
      showTopFilter={showTopFilter}
      showLeftFilter={showLeftFilter}
      showMoreButtonURL="/courses"
      bottomShowMoreButtonLabel="Search All Courses"
      topShowMoreButtonLabel="Browse All Courses"
      testId={testId}
    >
      <ProductsGrid<DiscoverySite>
        products={products}
        featuredProducts={featuredProducts}
        renderProduct={(product) => <SiteCard data={product} />}
        renderFeaturedProduct={(product) => (
          <SiteCard data={product} cardType="list" showFilter={filterEnabled} />
        )}
        productsCountPerRow={showLeftFilter ? 'three' : 'four'}
      />
    </AggregatedProductModule>
  );
}

CoursesModule.defaultProps = {
  testId: 'hub-courses-module',
};
